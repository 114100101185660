
import { Options, Vue } from "vue-class-component";
import {
  checkRolePermission,
  dateToDateString,
  dateToTime,
  ellipsisString
} from "@/app/infrastructures/misc/Utils";
import { GoodsTaxController } from "@/app/ui/controllers/GoodsTaxController";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { PRICING_GOODS_TAX } from "@/app/infrastructures/misc/RolePermission";
import debounce from "lodash/debounce";

@Options({
  components: {
    EmptyState
  },
  computed: {
    getCurrentRoute() {
      return this.$route.meta.title;
    },
    isCreateAble() {
      return checkRolePermission(PRICING_GOODS_TAX.CREATE) && !this.isError;
    },
    isDetailAble() {
      return checkRolePermission(PRICING_GOODS_TAX.DETAIL);
    }
  }
})
export default class GoodsTax extends Vue {
  statusSelect = false;
  statusName = "";
  status = "";
  firsRequest = true;
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Expired", value: "inactive" },
    { name: "Pending", value: "pending" }
  ];

  columns = [
    {
      name: "ID Pajak",
      key: "id",
      styleHead: "w-28 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<span class='text-center text-red-lp-200 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Kota Asal",
      key: "city",
      styleHead: "w-36 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class="whitespace-no-wrap">
              ${
                item.goodsTaxOriginCityName !== ""
                  ? item.goodsTaxOriginCityName?.replace(/,/gi, ", ")
                  : "-"
              }</span>`;
      }
    },
    {
      name: "Komoditas",
      key: "commodity",
      styleHead: "w-52 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                    ${ellipsisString(item.goodsTaxCommodityName, 20)}
                  </div>
                </div>`;
      }
    },
    {
      name: "Deminimus",
      key: "deminimus",
      styleHead: "w-24 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        const goodsTaxDeminimusValue =
          item.goodsTaxDeminimus !== null ? `${item.goodsTaxDeminimus} $` : "-";
        return `<span class="whitespace-no-wrap">
              ${goodsTaxDeminimusValue}</span>`;
      }
    },
    {
      name: "Biaya Masuk",
      key: "fee",
      styleHead: "w-24 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        const goodsTaxCoverChargeValue =
          item.goodsTaxCoverCharge !== null
            ? `${item.goodsTaxCoverCharge} %`
            : "-";
        return `<span class="whitespace-no-wrap">
              ${goodsTaxCoverChargeValue}</span>`;
      }
    },
    {
      name: "PPN",
      key: "ppn",
      styleHead: "w-24 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        const goodsTaxPPNValue =
          item.goodsTaxPPN !== null ? `${item.goodsTaxPPN} %` : "-";
        return `<span class="whitespace-no-wrap">
              ${goodsTaxPPNValue}</span>`;
      }
    },
    {
      name: "PPH",
      key: "pph",
      styleHead: "w-24 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        const goodsTaxPPH =
          item.goodsTaxPPH !== null ? `${item.goodsTaxPPH} %` : "-";
        return `<span class="whitespace-no-wrap">
              ${goodsTaxPPH}</span>`;
      }
    },
    {
      name: "Periode Pajak",
      key: "period",
      styleHead: "w-64 text-left",
      render: (item: any) => {
        return `<span>${this.formatDate(
          item.goodsTaxStartDate
        )} - ${this.formatDate(item.goodsTaxEndDate)}</span>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-32 text-left",
      styleBody: "",
      render: (item: any) => {
        if (item.status === "-") {
          return "-";
        }
        return `<div class="flex">
      <div
        class="rounded-full px-2 py-0 ${this.getClassBasedOnStatus(
          item.goodsTaxStatus.toLowerCase()
        )}"
      ><span class="capitalize">${item.goodsTaxStatus}</span></div></div>`;
      }
    }
  ];
  getClassBasedOnStatus(status: string) {
    if (status === "active")
      return "bg-green-lp-300 text-green-lp-200 font-medium";
    if (status === "pending")
      return "bg-yellow-lp-200 text-yellow-lp-100 font-medium";
    return "bg-red-300 text-red-lp-500 font-medium";
  }

  mounted() {
    GoodsTaxController.initFetchGoodsTax();
  }

  onRequest() {
    GoodsTaxController.fetchGoodsTax();
  }

  // advance filter
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    GoodsTaxController.setAdvanceFilterData(event);
    this.onRequest();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce(() => {
    GoodsTaxController.setAdvanceFilterData({
      cityOriginCode: "",
      commodity: "",
      statusGoodTax: ""
    });
    this.onRequest();
  }, 500);
  get advancedFilterData() {
    return GoodsTaxController.advanceFilterData;
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(new Date(date))}`;
  }

  // Add New Goods Tax
  toPageAdd() {
    this.$router.push("/tarif/goods-tax/add");
  }

  //Detail Goods Tax
  onClickRow(item: any) {
    this.$router.push(`/tarif/goods-tax/${item.id}`);
  }

  get goodsTax() {
    return GoodsTaxController.goodsTaxList.data;
  }

  get firstRequest() {
    return GoodsTaxController.firstRequest;
  }

  // Pagination Table
  get pagination() {
    return GoodsTaxController.goodsTaxList.pagination;
  }

  //Loading
  get isLoading() {
    return GoodsTaxController.isLoading;
  }

  //Error
  get isError() {
    return GoodsTaxController.isError;
  }

  //Error Cause
  get errorCause() {
    return GoodsTaxController.errorCause;
  }
}
