/* eslint-disable @typescript-eslint/camelcase */
import {
  AddGoodsTaxRequestInterface,
  EditGoodsTaxRequestInterface
} from "../contracts/GoodsTaxRequest";

export class AddGoodsTaxApiRequest implements AddGoodsTaxRequestInterface {
  private origin_city_code: string;
  private commodity_code: string;
  private deminimus: number;
  private cover_charge: number;
  private pph: number;
  private ppn: number;
  private start_date: string;
  private end_date: string;

  constructor(
    origin_city_code: string,
    commodity_code: string,
    deminimus: number,
    cover_charge: number,
    pph: number,
    ppn: number,
    start_date: string,
    end_date: string
  ) {
    this.origin_city_code = origin_city_code;
    this.commodity_code = commodity_code;
    this.deminimus = deminimus;
    this.cover_charge = cover_charge;
    this.pph = pph;
    this.ppn = ppn;
    this.start_date = start_date;
    this.end_date = end_date;
  }
  public toJSON(): string {
    return JSON.stringify({
      origin_city_code: this.origin_city_code,
      commodity_code: this.commodity_code,
      deminimus: this.deminimus,
      cover_charge: this.cover_charge,
      ppn: this.ppn,
      pph: this.pph,
      start_date: this.start_date,
      end_date: this.end_date
    });
  }
}

export class EditGoodsTaxApiRequest implements EditGoodsTaxRequestInterface {
  private id: number;
  private deminimus: number;
  private cover_charge: number;
  private pph: number;
  private ppn: number;
  private end_date: string;
  constructor(
    id: number,
    deminimus: number,
    cover_charge: number,
    pph: number,
    ppn: number,
    end_date: string
  ) {
    this.id = id;
    this.deminimus = deminimus;
    this.cover_charge = cover_charge;
    this.pph = pph;
    this.ppn = ppn;
    this.end_date = end_date;
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      deminimus: this.deminimus,
      cover_charge: this.cover_charge,
      ppn: this.ppn,
      pph: this.pph,
      end_date: this.end_date
    });
  }
}
