
import { Options, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import SelectSearchOriginDestination from "@/app/ui/components/select-search-origin-destination/index.vue";
import { GoodsTaxController } from "@/app/ui/controllers/GoodsTaxController";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { formatDateNumber } from "@/app/infrastructures/misc/Utils";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { CityData } from "@/domain/entities/Location";

@Options({
  components: {
    SelectSearchOriginDestination,
    Tooltip
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.onCloseSuccess();
      next();
    }
  }
})
export default class Add extends Vue {
  goBack() {
    this.$router.push(`/tarif/goods-tax/`);
  }

  // Get Data
  created() {
    this.getCommodityList();
    this.getCityList();
    GoodsTaxController.setErrorValidation(false);
    GoodsTaxController.setIsExist(false);
    GoodsTaxController.setOpenSuccess(false);
  }

  mounted() {
    GoodsTaxController.setPeriodeStart("");
    GoodsTaxController.setPeriodeEnd("");
  }

  // Handle Error
  get isLoading() {
    return GoodsTaxController.isLoading;
  }

  get isError() {
    return GoodsTaxController.isError;
  }
  get errorCause() {
    return GoodsTaxController.errorCause;
  }
  handleErrorClose() {
    GoodsTaxController.handleError();
  }
  // Modal Success
  get openSuccess() {
    return GoodsTaxController.openSuccess;
  }
  onCloseSuccess() {
    GoodsTaxController.setOpenSuccess(false);
  }

  // Save data
  onOpenSave() {
    return GoodsTaxController.setOpenModal(true);
  }
  get openSave() {
    return GoodsTaxController.openModal;
  }
  onCloseSave() {
    GoodsTaxController.setOpenModal(false);
  }

  get disableSubmit() {
    return GoodsTaxController.disableSubmit;
  }

  onSave() {
    this.onCloseSave();
    GoodsTaxController.handleError();
    GoodsTaxController.addGoodsTax({
      originCityCode: this.city.map((e: CityData) => e.code).toString(),
      commodityCode: this.commodityValue,
      deminimus: Number(String(this.deminimus).replace(/,/g, ".")),
      coverCharge: Number(String(this.biayaMasuk).replace(/,/g, ".")),
      pph: Number(String(this.PPH).replace(/,/g, ".")),
      ppn: Number(String(this.PPN).replace(/,/g, ".")),
      startDate: this.periodeStart,
      endDate: this.periodeEnd
    });
  }

  // Date
  invalidStartDate = false;
  invalidEndDate = false;
  get periodeStart(): any {
    return GoodsTaxController.periodeStart;
  }
  get periodeEnd(): any {
    return GoodsTaxController.periodeEnd;
  }
  inputDateStart(param: Date) {
    const result = new Date(formatDateNumber(param) + " " + "00:00" + " UTC");
    GoodsTaxController.setPeriodeStart(result);
    const secondDate = new Date(this.periodeEnd);
    if (
      param.getTime() > secondDate.getTime() ||
      param.getDate() === secondDate.getDate()
    ) {
      this.invalidStartDate = true;
      this.invalidEndDate = true;
    } else {
      this.invalidStartDate = false;
      this.invalidEndDate = false;
    }
    GoodsTaxController.setIsExist(false);
    GoodsTaxController.setErrorValidation(false);
  }

  get tomorrow(): Date {
    const date = new Date();
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }

  inputDateEnd(param: Date) {
    const result = new Date(formatDateNumber(param) + " " + "00:00" + " UTC");
    GoodsTaxController.setPeriodeEnd(result);
    const secondDate = new Date(this.periodeStart);
    if (
      param.getTime() < secondDate.getTime() ||
      param.getDate() === secondDate.getDate()
    ) {
      this.invalidEndDate = true;
      this.invalidStartDate = true;
    } else {
      this.invalidEndDate = false;
      this.invalidStartDate = false;
    }
    GoodsTaxController.setIsExist(false);
    GoodsTaxController.setErrorValidation(false);
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Tooltip
  isOpenDeminimus = false;
  isOpenBiayaMasuk = false;
  isOpenPPH = false;
  isOpenPPN = false;

  onOpenDeminimus(value: boolean) {
    this.isOpenDeminimus = value;
  }
  onOpenBiayaMasuk(value: boolean) {
    this.isOpenBiayaMasuk = value;
  }
  onOpenPPH(value: boolean) {
    this.isOpenPPH = value;
  }
  onOpenPPN(value: boolean) {
    this.isOpenPPN = value;
  }

  // Select Search
  cityName = "";
  commodityName = "";
  isOpenCity = false;
  isOpenCommodity = false;
  city = [];
  commodityValue = "";

  onOpenCommodity() {
    this.isOpenCommodity = true;
    this.commodityName.toLowerCase() === "all commodity"
      ? this.getCommodityList()
      : this.onSearchCommodity(this.commodityName);
  }

  onCloseCommodity() {
    this.isOpenCommodity = false;
  }

  onChooseCommodity(name: string, code: string) {
    this.commodityName = name;
    this.commodityValue = code;
    GoodsTaxController.setIsExist(false);
    GoodsTaxController.setErrorValidation(false);
  }

  getCommodityList() {
    CommodityController.getCommodityList(new RequestListCommodity());
  }

  getCityList() {
    GoodsTaxController.getCityList({
      search: "",
      status: "",
      page: 1,
      limit: 10,
      freeTradeZone: "yes"
    });
  }
  get errorMessage() {
    return GoodsTaxController.errorMessage;
  }
  get isErrorValidation() {
    return GoodsTaxController.isErrorValidation;
  }
  get cityList() {
    return GoodsTaxController.cityData.cityData.map(item => {
      return { id: item.id, name: item.name, code: item.code };
    });
  }
  get commodityList() {
    const list = CommodityController.commodities.data.map(item => {
      return {
        id: item.commodity_id,
        name: item.commodity_name,
        code: item.commodity_code
      };
    });
    list.length > 0 &&
      (this.commodityName.length === 0 ||
        this.commodityName.toLowerCase() === "all commodity") &&
      list.splice(0, 0, {
        id: 0,
        name: "All Commodity",
        code: "all"
      });
    return list;
  }
  onSearchCity(value: string) {
    this.cityName = value;
    if (value.length > 2 || !value) {
      GoodsTaxController.getCityList({
        search: this.cityName,
        status: "",
        page: 1,
        limit: 10,
        freeTradeZone: "yes"
      });
    }
  }
  onSearchCommodity(value: string) {
    this.commodityName = value;
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: value
      })
    );
  }

  // Payload
  deminimus: any = "";
  biayaMasuk: any = "";
  PPH: any = "";
  PPN: any = "";
  setDeminimus(e: any) {
    e.target.value = e.target.value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
    this.deminimus = e.target.value;
  }
  setBiayaMasuk(e: any) {
    e.target.value = e.target.value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
    this.biayaMasuk = e.target.value;
  }
  setPPH(e: any) {
    e.target.value = e.target.value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
    this.PPH = e.target.value;
  }
  setPPN(e: any) {
    e.target.value = e.target.value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
    this.PPN = e.target.value;
  }

  // Validation
  get isSaveableOthers(): boolean {
    return (
      !this.isExist &&
      !this.invalidStartDate &&
      !this.invalidEndDate &&
      this.periodeStart.length !== 0 &&
      this.periodeEnd.length !== 0
    );
  }
  get isSaveable(): boolean {
    return (
      this.isSaveableOthers &&
      !!this.city.length &&
      this.commodityValue.length > 0 &&
      Number(this.deminimus.replace(/,/, ".")) > 0 &&
      this.biayaMasuk.length > 0 &&
      this.PPH.length > 0 &&
      this.PPN.length > 0
    );
  }

  get isExist() {
    return GoodsTaxController.isExist;
  }
}
