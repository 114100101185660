import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { GoodsTax, GoodsTaxList } from "@/domain/entities/GoodsTax";
import { GoodsTaxPresenter } from "../presenters/GoodsTaxPresenter";
import {
  AddGoodsTaxApiRequest,
  EditGoodsTaxApiRequest
} from "@/data/payload/api/GoodsTaxApiRequest";
import { CityEntities } from "@/domain/entities/Location";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface GoodsTaxState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  goodsTaxList: GoodsTaxList;
  goodsTax: GoodsTax;
  firstRequest: boolean;
  page: number;
  limit: number;
}

@Module({ namespaced: true, dynamic: true, store, name: "goodstax" })
class GoodsTaxStore extends VuexModule implements GoodsTaxState {
  public goodsTaxList = new GoodsTaxList(new Pagination(1, 10), []);
  public goodsTax = new GoodsTax(
    0,
    "",
    "",
    "",
    0,
    0,
    0,
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  );
  public isLoading = false;
  public isError = false;
  public isLoadingAdd = false;
  public isLoadingEdit = false;
  public errorCause = "";
  public advanceFilterData = {
    cityOriginCode: "",
    commodity: "",
    statusGoodTax: ""
  };
  public firstRequest = true;
  public page = 1;
  public limit = 10;
  public openModal = false;
  public openSuccess = false;
  public isErrorEdit = false;
  public periodeStart: any = "";
  public periodeEnd: any = "";
  public isExist = false;
  public isErrorSave = false;
  public cityData = new CityEntities(new Pagination(1, 10), []);
  public errorMessage = "";
  public isErrorValidation = false;

  @Action
  public getGoodsTaxList(params: {
    cityOrigin: string;
    status: string;
    page: number;
    limit: number;
    commodityCode: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(GoodsTaxPresenter);
    presenter
      .getGoodsTaxList(
        params.cityOrigin,
        params.status,
        params.page,
        params.limit,
        params.commodityCode
      )
      .then((res: GoodsTaxList) => {
        this.setGoodsTax(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public editGoodsTax(params: {
    id: number;
    deminimus: number;
    coverCharge: number;
    pph: number;
    ppn: number;
    endDate: string;
  }) {
    this.setLoadingEdit(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(GoodsTaxPresenter);
    presenter
      .editGoodsTax(
        new EditGoodsTaxApiRequest(
          params.id,
          params.deminimus,
          params.coverCharge,
          params.pph,
          params.ppn,
          params.endDate
        )
      )
      .then(() => {
        this.setOpenModal(false);
        this.setOpenSuccess(true);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Tambah Pajak Barang Gagal", () => {
            MainAppController.closeErrorMessage();
            this.editGoodsTax(params);
          })
        );
      })
      .finally(() => {
        this.setLoadingEdit(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public addGoodsTax(params: {
    originCityCode: string;
    commodityCode: string;
    deminimus: number;
    coverCharge: number;
    pph: number;
    ppn: number;
    startDate: string;
    endDate: string;
  }) {
    this.setLoading(true);
    this.setDisableSubmit(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(GoodsTaxPresenter);
    presenter
      .addGoodsTax(
        new AddGoodsTaxApiRequest(
          params.originCityCode,
          params.commodityCode,
          params.deminimus,
          params.coverCharge,
          params.pph,
          params.ppn,
          params.startDate,
          params.endDate
        )
      )
      .then(() => {
        this.setOpenSuccess(true);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Pembuatan Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.addGoodsTax(params);
          })
        );
      })
      .finally(() => {
        this.setLoading(false);
        this.setOpenModal(false);
        this.setDisableSubmit(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public getCityList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
    freeTradeZone: string;
  }) {
    this.setLoadingAdd(true);
    const presenter = container.resolve(GoodsTaxPresenter);
    presenter
      .getCityList(
        params.search,
        params.status,
        params.page,
        params.limit,
        params.freeTradeZone
      )
      .then((res: CityEntities) => {
        this.setCityData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingAdd(false);
      });
  }

  @Action
  public handleError() {
    this.setError(false);
    this.setErrorEdit(false);
    this.setErrorSave(false);
  }

  @Action
  public getGoodsTaxDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(GoodsTaxPresenter);
    presenter
      .getDetailGoodsTax(id)
      .then((res: GoodsTax) => {
        this.setGoodsTaxDetail(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public fetchGoodsTax() {
    this.setFirstRequest(false);
    this.getGoodsTaxList({
      cityOrigin: this.advanceFilterData.cityOriginCode,
      status: this.advanceFilterData.statusGoodTax,
      page: this.goodsTaxList.pagination.page,
      limit: this.goodsTaxList.pagination.limit,
      commodityCode: this.advanceFilterData.commodity
    });
  }

  @Action
  public initFetchGoodsTax() {
    this.setFirstPage();
    this.setFirstRequest(true);
    this.getGoodsTaxList({
      cityOrigin: "",
      status: "",
      page: this.goodsTaxList.pagination.page,
      limit: this.goodsTaxList.pagination.limit,
      commodityCode: ""
    });
  }

  @Action
  public setFirstPage() {
    this.goodsTaxList.pagination.page = 1;
  }

  @Mutation
  private setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  @Mutation
  private setFirstRequest(firstRequest: boolean) {
    this.firstRequest = firstRequest;
  }

  @Mutation
  private setGoodsTax(obj: GoodsTaxList) {
    this.goodsTaxList = obj;
  }

  @Mutation
  private setGoodsTaxDetail(obj: GoodsTax) {
    this.goodsTax = obj;
    this.periodeEnd = obj.goodsTaxEndDate;
  }

  @Mutation
  private setError(err: boolean) {
    this.isError = err;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.errorCause = str;
  }

  @Mutation
  public async setAdvanceFilterData(value: any) {
    this.advanceFilterData = value;
  }

  @Mutation
  public setLoadingEdit(value: boolean) {
    this.isLoadingEdit = value;
  }

  @Mutation
  public setLoadingAdd(value: boolean) {
    this.isLoadingAdd = value;
  }
  @Mutation
  setOpenModal(value: boolean) {
    this.openModal = value;
  }
  @Mutation
  setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }
  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }
  @Mutation
  public setErrorSave(boolean: boolean) {
    this.isErrorSave = boolean;
  }
  @Mutation
  public setPeriodeStart(value: any) {
    this.periodeStart = value;
  }
  @Mutation
  public setPeriodeEnd(value: any) {
    this.periodeEnd = value;
  }
  @Mutation
  public setIsExist(value: boolean) {
    this.isExist = value;
  }
  @Mutation
  public async setCityData(res: CityEntities) {
    this.cityData = res;
  }
  @Mutation
  public setErrorMessage(value: string) {
    this.errorMessage = value;
  }
  @Mutation
  public setErrorValidation(value: boolean) {
    this.isErrorValidation = value;
  }

  // disable submit
  public disableSubmit = false;
  @Mutation
  private setDisableSubmit(val: boolean) {
    this.disableSubmit = val;
  }
}

export const GoodsTaxController = getModule(GoodsTaxStore);
