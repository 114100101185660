<template>
  <div class="left-0 flex flex-row items-center absolute justify-center z-50">
    <div
      class="bg-white rounded-md relative flex flex-row items-start"
      :style="`box-shadow: 0px 0px 8px 0px #b1b1b163; width: ${width}px`"
    >
      <slot>
        <div
          :class="
            `${customClass} text-black-lp-300 w-full flex flex-col text-12px`
          "
        >
          <div class="font-bold">
            {{ title }}
          </div>
          <div class="py-2 text-black-lp-300 font-normal">
            {{ message }}
          </div>
          <div class="text-red-lp-200 cursor-pointer underline font-bold">
            {{ footer }}
          </div>
        </div>
      </slot>
      <div
        :class="
          `${
            isArrowBottom
              ? 'arrow-bottom'
              : isArrowRight
              ? 'arrow-right'
              : isArrowLeft
              ? 'arrow-left'
              : ''
          } absolute`
        "
        style="margin-top: 20px"
      ></div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    title: {
      default: "",
      type: String
    },
    message: {
      default: "",
      type: String
    },
    footer: {
      default: "",
      type: String
    },
    isArrowBottom: {
      default: false,
      type: Boolean
    },
    isArrowRight: {
      default: false,
      type: Boolean
    },
    isArrowLeft: {
      default: true,
      type: Boolean
    },
    customClass: {
      default: "px-6 py-2",
      type: String
    },
    width: {
      default: 300,
      type: Number
    }
  }
})
export default class Tooltip extends Vue {}
</script>

<style>
.is-open {
  border-left: 30px solid transparent;
}
</style>
