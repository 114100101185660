
import { Options, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import { dateToDateString, dateToTime } from "@/app/infrastructures/misc/Utils";
import { GoodsTaxController } from "@/app/ui/controllers/GoodsTaxController";

@Options({
  components: {
    Tooltip
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.onCloseSuccess();
      next();
    }
  }
})
export default class Edit extends Vue {
  goBack() {
    this.$router.back();
  }

  // Get Data
  mounted() {
    this.getDetailData();
  }

  get tomorrow(): Date {
    const date = new Date();
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  getDetailData() {
    GoodsTaxController.getGoodsTaxDetail(this.id);
  }

  // Handle Error
  get isLoading() {
    return GoodsTaxController.isLoading;
  }

  get isError() {
    return GoodsTaxController.isError;
  }
  get errorCause() {
    return GoodsTaxController.errorCause;
  }
  handleErrorClose() {
    GoodsTaxController.handleError();
  }

  get isErrorEdit() {
    return GoodsTaxController.isErrorEdit;
  }

  get isErrorSave() {
    return GoodsTaxController.isErrorSave;
  }

  // Modal Success
  get openSuccess() {
    return GoodsTaxController.openSuccess;
  }
  onCloseSuccess() {
    GoodsTaxController.setOpenSuccess(false);
  }

  // Save data
  onOpenSave() {
    return GoodsTaxController.setOpenModal(true);
  }
  get openSave() {
    return GoodsTaxController.openModal;
  }
  onCloseSave() {
    GoodsTaxController.setOpenModal(false);
  }

  onSave() {
    this.onCloseSave();
    GoodsTaxController.handleError();
    GoodsTaxController.editGoodsTax({
      id: this.detailData.id,
      deminimus: Number(String(this.deminimus).replace(/,/g, ".")),
      coverCharge: Number(String(this.biayaMasuk).replace(/,/g, ".")),
      pph: Number(String(this.PPH).replace(/,/g, ".")),
      ppn: Number(String(this.PPN).replace(/,/g, ".")),
      endDate: this.periodeEnd
    });
  }

  // Date
  invalidEndDate = false;
  get periodeEnd(): any {
    return new Date(GoodsTaxController.periodeEnd);
  }
  inputDateEnd(date: Date) {
    const starDate = new Date(this.detailData.startPeriod);
    const selectedDate = new Date(date);
    this.invalidEndDate = selectedDate === starDate || selectedDate < starDate;
    GoodsTaxController.setPeriodeEnd(selectedDate);
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Tooltip
  isOpenDeminimus = false;
  isOpenBiayaMasuk = false;
  isOpenPPH = false;
  isOpenPPN = false;

  onOpenDeminimus(value: boolean) {
    this.isOpenDeminimus = value;
  }
  onOpenBiayaMasuk(value: boolean) {
    this.isOpenBiayaMasuk = value;
  }
  onOpenPPH(value: boolean) {
    this.isOpenPPH = value;
  }
  onOpenPPN(value: boolean) {
    this.isOpenPPN = value;
  }

  // Payload
  deminimus: any = "";
  biayaMasuk: any = "";
  PPH: any = "";
  PPN: any = "";

  isSaveable() {
    return (
      !this.invalidEndDate &&
      this.deminimus.length > 0 &&
      this.biayaMasuk.length > 0 &&
      this.PPH.length > 0 &&
      this.PPN.length > 0
    );
  }

  setDeminimus(e: any) {
    e.target.value = e.target.value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
    this.deminimus = e.target.value;
  }
  setBiayaMasuk(e: any) {
    e.target.value = e.target.value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
    this.biayaMasuk = e.target.value;
  }
  setPPH(e: any) {
    e.target.value = e.target.value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
    this.PPH = e.target.value;
  }
  setPPN(e: any) {
    e.target.value = e.target.value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
    this.PPN = e.target.value;
  }

  // Utils
  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  // dummy data
  get detailData() {
    const detail = GoodsTaxController.goodsTax;
    this.deminimus = String(detail.goodsTaxDeminimus).replace(/\./g, ",");
    this.biayaMasuk = String(detail.goodsTaxCoverCharge).replace(/\./g, ",");
    this.PPH = String(detail.goodsTaxPPH).replace(/\./g, ",");
    this.PPN = String(detail.goodsTaxPPN).replace(/\./g, ",");
    GoodsTaxController.setPeriodeEnd(detail.goodsTaxEndDate);
    return {
      ...detail,
      id: detail.id,
      city: detail.goodsTaxOriginCityName,
      commodity: detail.goodsTaxCommodityName,
      deminimus: detail.goodsTaxDeminimus,
      fee: detail.goodsTaxCoverCharge,
      ppn: detail.goodsTaxPPN,
      pph: detail.goodsTaxPPH,
      startPeriod: detail.goodsTaxStartDate,
      endPeriod: detail.goodsTaxEndDate,
      createdAt: detail.goodsTaxCreatedAt,
      updatedAt: detail.goodsTaxUpdatedAt,
      createdBy: detail.goodsTaxCreatedName,
      updatedBy: detail.goodsTaxUpdatedName,
      status: detail.goodsTaxStatus
    };
  }
}
