
import { PropType } from "vue";
import { Options, prop, Vue } from "vue-class-component";
class Props {
  placeholder = prop<string>({
    default: "",
    type: String
  });
  value = prop<string>({
    default: "",
    type: String
  });
  width = prop<string>({
    default: "15vw",
    type: String
  });
  noSpaceLeft = prop<boolean>({
    default: false,
    type: Boolean
  });
  noSpaceRight = prop<boolean>({
    default: false,
    type: Boolean
  });
  isError = prop<boolean>({
    default: false,
    type: Boolean
  });
  dataSearch = prop<any>({
    default: [],
    type: Array
  });
  onSearch = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onOpenSearch = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onCloseSearch = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onChooseSearch = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  isOpenSearch = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  notFound = prop<boolean>({
    default: false,
    type: Boolean
  });
  thereIsAll = prop<boolean>({
    default: false,
    type: Boolean
  });
}
@Options({
  name: "SelectSearch",
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class extends Vue.with(Props) {
  onExpand() {
    this.onOpenSearch();
    const refs: any = this.$refs;
    refs.selectSearch.focus();
  }
  get getAllData() {
    return this.thereIsAll ? 1 : 0;
  }
  get isClickChevron() {
    if (this.isDisabled) {
      return false;
    }
    return this.isOpenSearch ? this.onCloseSearch() : this.onExpand();
  }
}
