import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "flex gap-x-4" }
const _hoisted_3 = { class: "font-semibold text-20px whitespace-no-wrap text-black-lp-200 capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_advanced_filter = _resolveComponent("advanced-filter")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.$route?.meta.name), 1),
          _createVNode(_component_advanced_filter, {
            "model-value": _ctx.advancedFilterData,
            isCityOrigin: true,
            commodity: true,
            isChooseTypeAccount: true,
            isStatusGoodTax: true,
            isDisabled: false,
            onChange: _ctx.onChangeAdvancedFilter,
            onOpened: _ctx.onOpenedAdvancedFilter,
            onReset: _ctx.onResetAdvancedFilter
          }, null, 8, ["model-value", "onChange", "onOpened", "onReset"])
        ]),
        _createVNode(_component_lp_button, {
          onClick: _ctx.toPageAdd,
          title: "Buat Pajak Barang",
          textColor: "white"
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }),
    (_ctx.isError && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause,
          onTryAgain: _ctx.onRequest
        }, null, 8, ["errorType", "onTryAgain"]))
      : (_ctx.goodsTax.length === 0 && _ctx.firstRequest && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 1,
            data: "data Pajak Barang"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 2,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            loading: _ctx.isLoading,
            columns: _ctx.columns,
            data: _ctx.goodsTax,
            onClick: _ctx.onClickRow,
            onRequest: _ctx.onRequest,
            class: "my-2",
            isDetailAble: _ctx.isDetailAble
          }, null, 8, ["pagination", "loading", "columns", "data", "onClick", "onRequest", "isDetailAble"]))
  ]))
}