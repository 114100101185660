
import { Vue, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import {
  checkRolePermission,
  dateToDateString,
  dateToTime
} from "@/app/infrastructures/misc/Utils";
import { PRICING_GOODS_TAX } from "@/app/infrastructures/misc/RolePermission";
import { GoodsTaxController } from "@/app/ui/controllers/GoodsTaxController";

@Options({
  components: {
    DetailLayout,
    Tooltip
  },
  computed: {
    isEditAble() {
      return checkRolePermission(PRICING_GOODS_TAX.EDIT);
    }
  }
})
export default class Detail extends Vue {
  mounted() {
    this.fetchDetail();
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(new Date(date))}`;
  }

  formatDateWithoutTime(date: string) {
    return `${dateToDateString(new Date(date))}`;
  }

  get detailGoodsTax() {
    return GoodsTaxController.goodsTax;
  }

  get isLoading() {
    return GoodsTaxController.isLoading;
  }

  get isError() {
    return GoodsTaxController.isError;
  }

  get errorCause() {
    return GoodsTaxController.errorCause;
  }

  get status() {
    switch (this.detailGoodsTax.goodsTaxStatus.toLowerCase()) {
      case "inactive":
        return "expired";
      case "pending":
        return "pending";
      case "active":
        return "active";
      default: {
        return "active";
      }
    }
  }

  get styleStatus() {
    switch (this.detailGoodsTax.goodsTaxStatus.toLowerCase()) {
      case "inactive":
        return "bg-red-lp-600 text-red-lp-500 font-medium";
      case "pending":
        return "text-yellow-lp-100 font-medium bg-yellow-lp-200 font-medium";
      case "active":
        return "bg-green-lp-300 text-green-lp-200 font-medium";
      default: {
        return "bg-green-lp-300 text-green-lp-200 font-medium";
      }
    }
  }

  fetchDetail() {
    GoodsTaxController.getGoodsTaxDetail(Number(this.$route.params.id));
  }

  goBack() {
    this.$router.push("/tarif/goods-tax");
  }

  goEdit() {
    this.$router.push(`/tarif/goods-tax/${this.$route.params.id}/edit`);
  }

  // Tooltip
  isOpenDeminimus = false;
  isOpenBiayaMasuk = false;
  isOpenPPH = false;
  isOpenPPN = false;

  onOpenDeminimus(value: boolean) {
    this.isOpenDeminimus = value;
  }
  onOpenBiayaMasuk(value: boolean) {
    this.isOpenBiayaMasuk = value;
  }
  onOpenPPH(value: boolean) {
    this.isOpenPPH = value;
  }
  onOpenPPN(value: boolean) {
    this.isOpenPPN = value;
  }
}
